import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'
import smoothScroll from 'scroll-to-element'

const ServiceMobileCta = () => (
  <section className="service-mobile cto">
    <div className="container">
      <div className="row py-5">
        <div className="col-xs-12 col-sm-4 col-lg-6">
          <div className="lead text-left text-sm-right">
            <h3 className="seo-span seo-strong text-muted">
            Aplicaciones móviles a medida
            </h3>
            <br />
            <span className="text-service">
            para iOS y Android
            </span>
            .<br />
            <a
              href="#contact-partial"
              data-form-category="mobileapps"
              className="btn btn-outline-extra1 my-2"
              id="custom-mobile-app-dev-request-btn"
              onClick={event => {
                event.preventDefault()
                smoothScroll('#contact-partial', { duration: 500 })
              }}
            >
              Pregunte por la oferta
            </a>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 col-lg-6">
          <ul className="fa-ul">
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Trabajar con un equipo de desarrolladores {' '}
              <strong className="seo-regular"> de aplicaciones Android </strong>  y especialistas en Kotlin
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Contrate a <strong className="seo-regular">desarrolladores de iOS</strong>{' '}
              que dominen Swift
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Cree una aplicación para Windows en .NET
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Cree una aplicación móvil, web e híbrida
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Consiga una UI / UX funcional y bonita
            </li>
          </ul>
        </div>
      </div>
      <hr />
    </div>
  </section>
)

export default ServiceMobileCta
