import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAndroid,
  faApple,
} from '@fortawesome/fontawesome-free-brands'
import Link from '../../common/Link'

const ServiceMobileOffer = () => (
  <section className="service-mobile">
    <div className="container">
      <div className="row py-5">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right mb-2">
            <span className="text-muted seo-regular">Soluciones de aplicaciones</span>
            <br />
            móviles para corporaciones
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <div className="card-group text-center">
            <div className="card">
              <div className="card-body">
                <h3>MOBILE DEVELOPMENT OUTSOURCING</h3>
                <p className="text-sm text-muted text-italic mt-2">
                Para saber qué modelo es el ideal para usted, {' '}
                  <Link to="/services/poland-software-outsourcing/">
                  consulte nuestra guía de subcontratación.
                  </Link>
                  .
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h3>CONVIERTA SU SITIO WEB EN UNA APLICACIÓN MÓVIL</h3>
                <p className="text-sm text-muted text-italic mt-2">
                Dé el siguiente paso y convierta su plataforma actual en una aplicación rentable.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h3>APLICACIÓN MÓVIL A MEDIDA</h3>
                <p className="text-sm text-muted text-italic mt-2">
                Estamos preparados para desarrollar una aplicación de cualquier tipo.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row pb-2">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right mb-2">
            <span className="text-muted seo-regular">Diseño de aplicaciones móviles:</span>
            <br />
            <strong>soluciones</strong>
          </h2>
        </div>
        <div className="col-sm-12 col-md-8 pt-2">
          <div className="media feature">
            <div className="feature-icon mr-3">
              <FontAwesomeIcon icon={faApple} size="2x" className="fa-fw" />
            </div>
            <div className="media-body">
              <strong className="seo-h3">DISEÑO DE APLICACIONES PARA IOS</strong>
              <p className="text-muted">
              Personalizado para iPhones, iPads y Apple Watches para captar la atención de sus clientes en la App Store y que satisfará a los usuarios más exigentes.
              </p>
            </div>
          </div>
          <div className="media feature">
            <div className="feature-icon mr-3">
              <FontAwesomeIcon icon={faAndroid} size="2x" className="fa-fw" />
            </div>
            <div className="media-body">
              <strong className="seo-h3">DISEÑO DE APLICACIONES PARA ANDROID</strong>
              <p className="text-muted">
              Mantener una interfaz de usuario coherente y eficaz en el ecosistema Android con el fin de satisfacer las necesidades del mayor grupo de usuarios de móviles.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceMobileOffer
