import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Link from './Link'

const CtaMobile = ({ lightTheme }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          imgLight: file(relativePath: { regex: "/cto_mobile_light_bg.png/" }) {
            childImageSharp {
              fluid(quality: 85) {
                src
              }
            }
          }
          img: file(relativePath: { regex: "/cto_mobile_bg.png/" }) {
            childImageSharp {
              fluid(quality: 85) {
                src
              }
            }
          }
        }
      `}
      render={({ imgLight, img }) => {
        const image = lightTheme
          ? imgLight.childImageSharp.fluid.src
          : img.childImageSharp.fluid.src
        return (
          <section
            id="cta-mobile"
            className={`cto ${lightTheme ? 'bg-faded' : 'bg-dark'}`}
          >
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-4 col-lg-2">
                  <img src={image} alt="Mobile Apps" className="cta-image" />
                </div>
                <div className="col-12 col-sm-8 col-lg-10">
                  <div className="row">
                    <div className="col-md-12">
                      <h3>Cree una aplicación móvil en 3 sencillos pasos</h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-lg-3">
                      <div className="step">
                        <span className="counter">1</span>
                        <h4>Especificación:</h4>
                        <p className="text-muted m-0">
                        proporcione la especificación a nuestro analista de negocios
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="step">
                        <span className="counter">2</span>
                        <h4>Desarrollo:</h4>
                        <p className="text-muted m-0">
                        Programación, diseño,
                          <br />
                          implementación y pruebas!
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3 mt-sm-4 mt-md-0">
                      <div className="step">
                        <span className="counter">3</span>
                        <h4>Publicación:</h4>
                        <p className="text-muted m-0">
                           enviamos la aplicación
                          <br />
                          a la App Store y Play Store
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3 mt-sm-4 mt-md-0">
                      <div className="step">
                        <h4>¿Está interesado?</h4>
                        <Link to="/estimate-app-cost/" className="btn btn-outline-warning">
                        Consigue un presupuesto!
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      }}
    />
  )
}

export default CtaMobile
