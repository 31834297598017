import React from 'react'

const ServiceMobileTags = () => (
  <section className="service-mobile bg-faded py-5">
    <div className="container">
      <div className="row mb-5">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right mb-1">
            <span className="text-muted seo-regular">Desarrollo de aplicaciones móviles</span>
            <br />
            <strong>- costes</strong>
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <div className="row">
            <div className="col-sm-4">
              <strong>Valoración de Offshoring</strong>
              <br />
              Alta calidad a bajo precio
            </div>
            <div className="col-sm-4">
              <strong>Time & Materials</strong>
              <br />
              pago en cómodos plazos
            </div>
            <div className="col-sm-4">
              <strong>GRATIS</strong>
              <br />
              Estimación gratuita de su idea
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right">
            <span className="text-muted seo-regular">Desarrollo móvil
</span>
            <br />
            servicios 
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <ul className="list-unstyled list-inline list-badges lead">
            <li>
              <span className="badge badge-light">Estrategia móvil</span>
            </li>
            <li>
              <span className="badge badge-light">Análisis de negocio</span>
            </li>
            <li>
              <span className="badge badge-light">
              Diseño de la mejor aplicación móvil
              </span>
            </li>
            <li>
              <strong className="badge badge-light">
              Prototipado de la aplicación
              </strong>
            </li>
            <li>
              <span className="badge badge-light">
              Integración con la nube y la API
              </span>
            </li>
            <li>
              <strong className="badge badge-light">
              Pruebas de QA
              </strong>
            </li>
            
            <li>
              <span className="badge badge-light">Publicación en la App Store</span>
            </li>
            <li>
              <span className="badge badge-light">Mantenimiento y apoyo</span>
            </li>
          </ul>
          <div className="clearfix" />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-4" />
        <div className="col-sm-12 col-md-8" />
      </div>
    </div>
  </section>
)

export default ServiceMobileTags
