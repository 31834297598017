import React from 'react'

const MvpReasons = () => (
  <section className="section bg-faded">
    <div className="container">
      <h2 className="text-center mt-2">
      Equipe su startup con ingenieros full-stack
      </h2>
      <div className="card-group card-group-transparent my-2 mvp-reasons">
        <div className="card">
          <div className="card-body">
          Creación rápida de prototipos:
            <br />
            <em className="text-muted">
              <abbr title="Minimum Viable Product">el MVP</abbr> se entrega en 12-16 semanas
            </em>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
          Apoyo total del equipo de:
            <br />
            <em className="text-muted">
            analista, PM, programadores, diseñadores, probadores
            </em>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
          Una UI/UX bonita y fácil de usar
            <br/>
            <em className="text-muted">
            diseñada por nuestros&nbsp;
              <span className="text-nowrap"></span> diseñadores.    
            </em>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h3 className="seo-strong m-0">Una programación ágil
gestionada</h3>
            <br />
            <em className="text-muted">de acuerdo con los principios de SCRUM</em>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
          Apoyo a largo plazo
            <br />
            <em className="text-muted">
            continúe con el desarrollo del producto más allá del periodo de MVP
            </em>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
          Subcontratación amigable 
            <br />
            <em className="text-muted">
              for con la gestión remota: programación sin barreras
              
            </em>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default MvpReasons
